/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-display: swap;
}

.ztextcenter {
  text-align: center;
}

.errorMsg {
  text-align: center;
  color: red;
}

.iconTextAlign {
  vertical-align: middle;
  padding-right: 3px;
  padding-bottom: 5px;
}

/* Home Page Loading  */


.homeLoadingCss {
  font-family: 'Roboto', sans-serif;
  text-align: center !important;
  height: 100%;
  display: grid;
}

.h1,
h1 {
  font-weight: 100;
}

.zspace {
  /* This fills the remaining space, by using flexbox. 
       Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}
